import React, { Component, useState } from 'react';
import {Link} from 'react-router-dom';
import Modal2 from 'react-bootstrap/Modal';
import train from '../images/train.jpg';
import Footer from '../components/footer';
import coding from '../images/coding.png';
import lecture from '../images/lecture.png';
import certificate from '../images/certificate.png';
import Navigation from './navigation.js';
import calendar from '../images/calendar.jpg';
import $ from 'jquery';



const Training = () =>{

    const [nominationSize, setnominationSize] = useState("");
    const [schoolName, setschoolName] = useState("");
    const [schoolAddress, setschoolAddress] = useState("");
    const [schoolEmail, setschoolEmail] = useState("");
    const [schoolWebsite, setschoolWebsite] = useState("");
    const [useATC, setuseATC] = useState("");
    const [selectProgramme, setSelectProgramme] = useState("");
    const [schoolContactTitle, setschoolContactTitle] = useState("");
    const [schoolContactFirstName, setschoolContactFirstName] = useState("");
    const [schoolContactLastName, setschoolContactLastName] = useState("");
    const [schoolContactMobileNo, setschoolContactMobileNo] = useState("");
    const [schoolContactEmail, setschoolContactEmail] = useState("");
    const [schoolContactDesignation, setschoolContactDesignation] = useState("");
    const [nomineeTitle1, setnomineeTitle1] = useState("");
    const [nomineeFirstName1, setnomineeFirstName1] = useState("");
    const [nomineeLastName1, setnomineeLastName1] = useState("");
    const [nomineeMobileNo1, setnomineeMobileNo1] = useState("");
    const [nomineeEmail1, setnomineeEmail1] = useState("");
    const [nomineeDOB1, setnomineeDOB1] = useState("");
    const [nomineeSubjectStatus1, setnomineeSubjectStatus1] = useState("");
    const [nomineeComputerStatus1, setnomineeComputerStatus1] = useState("");
    const [nomineeProficiency1, setnomineeProficiency1] = useState("");
    const [nomineeNtrcStatus1, setnomineeNtrcStatus1] = useState("");
    const [nomineeTitle2, setnomineeTitle2] = useState("");
    const [nomineeFirstName2, setnomineeFirstName2] = useState("");
    const [nomineeLastName2, setnomineeLastName2] = useState("");
    const [nomineeMobileNo2, setnomineeMobileNo2] = useState("");
    const [nomineeEmail2, setnomineeEmail2] = useState("");
    const [nomineeDOB2, setnomineeDOB2] = useState("");
    const [nomineeSubjectStatus2, setnomineeSubjectStatus2] = useState("");
    const [nomineeComputerStatus2, setnomineeComputerStatus2] = useState("");
    const [nomineeProficiency2, setnomineeProficiency2] = useState("");
    const [nomineeNtrcStatus2, setnomineeNtrcStatus2] = useState("");
    const [loadingModalIsOpen, setloadingModalIsOpen] = useState(false);



    




async function handleSchoolName(e){
    setschoolName(e.target.value)
}

async function handleSchoolAddress(e){
    setschoolAddress(e.target.value)
}

async function handleSchoolEmail(e){
    setschoolEmail(e.target.value)
}

async function handleSchoolWebsite(e){
    setschoolWebsite(e.target.value)
}

async function handleUseATC(e){
    setuseATC(e.target.value)
}

async function handleSelectProgramme(e){
    setSelectProgramme(e.target.value)
}

async function handleSchoolContactTitle(e){
    setschoolContactTitle(e.target.value)
}

async function handleSchoolContactFirstname(e){
    setschoolContactFirstName(e.target.value)
}

async function handleSchoolContactLastname(e){
    setschoolContactLastName(e.target.value)
}

async function handleSchoolContactMobileNo(e){
    setschoolContactMobileNo(e.target.value)
}

async function handleSchoolContactEmail(e){
    setschoolContactEmail(e.target.value)
}

async function handleSchoolContactDesignation(e){
    setschoolContactDesignation(e.target.value)
}


async function handleTeacherNomination(e){
    setnominationSize(e.target.value)

     const nomineeSize = $('.nomineeSize').val()
     console.log(nomineeSize)
     if(nomineeSize === '1'){
        $('.nominee1').fadeIn('slow') 
        $('.nominee2').fadeOut('slow') 
     }else if(nomineeSize === '2' && selectProgramme != "council"){
         $('.nominee1, .nominee2').fadeIn('fast')  
     }else if(nomineeSize === '2' && selectProgramme == "council"){
               alert("You can only nominate 1 teacher under the International Literacy Day Programme")
     }else{
        $('.nominee1, .nominee2').fadeOut('fast')  
     }
     
}

async function handleSchoolNomineeTitle1(e){
    setnomineeTitle1(e.target.value)
}

async function handleSchoolNomineeFirstname1(e){
    setnomineeFirstName1(e.target.value)
}

async function handleSchoolNomineeLastname1(e){
    setnomineeLastName1(e.target.value)
}

async function handleSchoolNomineeMobileno1(e){
    setnomineeMobileNo1(e.target.value)
}

async function handleSchoolNomineeEmail1(e){
    setnomineeEmail1(e.target.value)
}

async function handleSchoolNomineeDOB1(e){
    setnomineeDOB1(e.target.value)
}

async function handleSchoolNomineeSubjectStatus1(e){
  setnomineeSubjectStatus1(e.target.value)
}

async function handleSchoolNomineeComputerStatus1(e){
    setnomineeComputerStatus1(e.target.value)
}

async function handleSchoolNomineeProficiency1(e){
    setnomineeProficiency1(e.target.value)
}

async function handleSchoolNomineeNtrcStatus1(e){
    setnomineeNtrcStatus1(e.target.value)
}

async function handleSchoolNomineeTitle2(e){
    setnomineeTitle2(e.target.value)
}

async function handleSchoolNomineeFirstname2(e){
    setnomineeFirstName2(e.target.value)
}

async function handleSchoolNomineeLastname2(e){
    setnomineeLastName2(e.target.value)
}

async function handleSchoolNomineeMobileno2(e){
    setnomineeMobileNo2(e.target.value)
}

async function handleSchoolNomineeEmail2(e){
    setnomineeEmail2(e.target.value)
}

async function handleSchoolNomineeDOB2(e){
    setnomineeDOB2(e.target.value)
}

async function handleSchoolNomineeSubjectStatus2(e){
    setnomineeSubjectStatus2(e.target.value)
  }
  

async function handleSchoolNomineeComputerStatus2(e){
    setnomineeComputerStatus2(e.target.value)
}

async function handleSchoolNomineeProficiency2(e){
    setnomineeProficiency2(e.target.value)
}

async function handleSchoolNomineeNtrcStatus2(e){
    setnomineeNtrcStatus2(e.target.value)
}



async function submitCpdRegistrationObject(){ 

    if( schoolName === "" || schoolAddress === "" || schoolEmail=== "" || selectProgramme === "" || schoolContactTitle == "" || schoolContactFirstName === "" || schoolContactLastName === "" || schoolContactEmail === ""  || schoolContactDesignation === "" || schoolContactMobileNo == "" || nominationSize == "" || nomineeTitle1 == "" || nomineeFirstName1 == "" || nomineeLastName1 == "" || nomineeMobileNo1 == "" || nomineeEmail1 == "" || nomineeComputerStatus1 == "" || nomineeDOB1 == "" || nomineeNtrcStatus1 == "" || nomineeProficiency1 == "" || nomineeTitle1 == "" || nomineeFirstName1 == "" || nomineeLastName1 == "" || nomineeMobileNo1 == "" || nomineeEmail1 == "" || nomineeComputerStatus1 == "" || nomineeDOB1 == "" || nomineeNtrcStatus1 == "" || nomineeProficiency1 == "" ){

        return alert("fill all fields")
 }

 if(schoolEmail === schoolContactEmail){ 
    return alert("school email and School contact email cannot be same")
 }


 if(schoolEmail === nomineeEmail1){ 
    return alert("school email and nominee email cannot be same")
 }

 if(schoolEmail === nomineeEmail2){ 
    return alert("school email and nominee email cannot be same")
 }

 if(schoolContactEmail === nomineeEmail2){ 
    return alert("school contact email and nominee email cannot be same")
 }

 if(schoolContactEmail === nomineeEmail1){ 
    return alert("school contact email and nominee email cannot be same")
 }


 if(nomineeEmail1 === nomineeEmail2) {
    return alert("nominee email cannot be in replicates")
 }

    setloadingModalIsOpen(true)

    const registrationData = {
        schoolName,
        email: schoolEmail,
        address: schoolAddress,
        website: schoolWebsite,
        customerStatus: useATC,
        programme: selectProgramme,
        representativeTitle: schoolContactTitle,
        representativeFirstName: schoolContactFirstName,
        representativeLastName: schoolContactLastName,
        representativeEmail: schoolContactEmail,
        representativeMobile: schoolContactMobileNo,
        representativeDesignation: schoolContactDesignation, 
        nominatedTeachers: nominationSize,
        nominatedTitle1: nomineeTitle1,
        nominatedFirstName1: nomineeFirstName1,
        nominatedLastName1: nomineeLastName1,
        nominatedMobile1: nomineeMobileNo1,
        nominatedEmail1: nomineeEmail1,
        nominatedDOB1: nomineeDOB1,
        nominatedProficiency1: nomineeProficiency1,
        nominatedComputerStatus1: nomineeComputerStatus1, 
        nominatedSubjectStatus1: nomineeSubjectStatus1,
        nominatedNtrcStatus1: nomineeNtrcStatus1,
        nominatedTitle2: nomineeTitle2,
        nominatedFirstName2: nomineeFirstName2,
        nominatedLastName2: nomineeLastName2,
        nominatedMobile2: nomineeMobileNo2,
        nominatedEmail2: nomineeEmail2,
        nominatedDOB2: nomineeDOB2,
        nominatedProficiency2: nomineeProficiency2,
        nominatedComputerStatus2: nomineeComputerStatus2, 
        nominatedSubjectStatus2: nomineeSubjectStatus2,
        nominatedNtrcStatus2: nomineeNtrcStatus2 

    }
    

    console.log(registrationData)

    const submitRegistrationData = await fetch('https://cslabsapi-b05a5c3c41f0.herokuapp.com/cslabs/v1/cpdreg',{
        method:"POST",
        headers:{
          "content-type":"application/JSON"
        },
        body: JSON.stringify(registrationData),
        mode:"cors",
        credentials:"include"

        })

        .then(response => response.json())
        .then(response => {

            if(response){
                alert("Registration Successfully saved to Database")
                setloadingModalIsOpen(false)
            }

            if(!response){
                alert("School Already registered on database")
                setloadingModalIsOpen(false)
            }

     
      
   
        })
}


async function formValidation(){

}



function slideToWork(){ 

    $('html,body').animate({
        scrollTop: $(".nominate").offset().top},
        1000);

}

return(
<>
<Navigation/>
<div className="row"> 


<div className="col-12 col-md-6 black-bg standard-padding standard-padding-mobile"> 

   <h3 className='body-font white-text standard-margin-top'> Computer Science Continuous Professional Development Plan  </h3>
   <p className="body-font white-text standard-margin-top"> 
    Empower your ICT teachers with the Computer Science knowledge
    required to deliver a wholostic education to your
    learners through the life long Code 4TEEN CPD Plan.

    Enjoy a standardized measurement and evaluation process for 
    both growing and monitoring the capacity of your ICT teacher 
    to deliver computer science modules in class using tested and 
    trusted Pedagogy.
   </p>

   <Link To="#" onClick={slideToWork} className="btn c4t-btn white-text body-font thin-font full-width"> Nominate Teacher Now </Link>

   

</div>

<div className="col-12 col-md-6 pOff"> 
   <img src={train} width="100%" />

</div>

<div className="col-12 pOff yellow text-center"> 
  

</div>

<div className="col-12 black-bg standard-padding-top-bottom text-center"> 
  <h3 className="white-text mOff">Benefits For Teachers </h3>

</div>






       <div className="col-12 col-md-4 c4t-green-bg standard-padding standard-padding-mobile"> 

       <img src={coding} width="20%" />


   <h3 className='body-font white-text standard-margin-top'> Learn the fundamentals of computer language </h3>
   <p className="body-font white-text standard-margin-top"> 
    It's back to CS 101. Application of knowledge requires understanding, so get ready to load up those
    text-editors and start coding. We build teacher capacity across the three fundamental web programming languages covering
    both interface design and logical reasoning. 

    For a connected learning experience, teachers cover HTML, CSS and Javascript over an intensive live long learning journey guaranteed 
    by their school management.
   </p>

  
   

</div>

<div className="col-12 col-md-4 c4t-yellow-bg standard-padding standard-padding-mobile"> 
<img src={lecture} width="20%" />

<h3 className='body-font white-text standard-margin-top'> Master the Pedagogy for Knowledge Transfer  </h3>
<p className="body-font white-text standard-margin-top"> 
 Understanding the fundamental of Computer languages is a plus but the ability to communicate this knowledge to 
 the target learners is more important. Code 4TEEN's capacity building covers methodology training on the science of
 teaching, required to guarantee knowledge transfer in a classroom setting. 

 Executed using contextual learning materials which teachers will use in the day-to-day classes after training, the entire process
 is designed to ensure teachers are not just coders but conversant with best-practice for teaching coding in class.
</p>


</div>


<div className="col-12 col-md-4 c4t-grey-bg standard-padding standard-padding-mobile"> 
<img src={certificate} width="20%" />

<h3 className='body-font white-text standard-margin-top'> Boost teaching career through certification  </h3>
<p className="body-font white-text standard-margin-top"> 
  Give your teachers the required confidence to execute computer science syllabus at your school and also be influencers in the global community. By completing the 
  training modules, teachers are qualified to seat for a certification exam to validate their capability to successful deliver 
  the expected learning outcome for each class group. 

  The level-based nature of our certification also serves an added incentive to keep teachers motivated all through their up-skilling
  journey
</p>

</div>



{/*----start of media section----*/}

<div className="row mt-5 visual-padding-1">
    <div className="col-12 text-center">
        <h4> Testimonial Videos From Some Teachers Currently Enrolled under the Computer Science CPD Plan</h4>
        <hr/>
    </div>
    <div className="col-12 col-md-6 text-center">
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/RlLtBsFItaQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div className="col-12 col-md-6">
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/uIPZDC1FOC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> </div>
</div>

{/*----end of media section----*/}

<div className="col-12 pOff"> 
<img src={calendar} width="100%"/>

</div>
<div className='col-12 text-center black-bg '>
                    <a href="https://res.cloudinary.com/code4teen-tech/image/upload/v1670431099/Brochures/2022.pdf" className="btn c4t-btn standard-margin-top white-text body-font thin-font full-width" download> Download 2022/2023 Academic Calendar </a>
                    <a href="https://res.cloudinary.com/code4teen-tech/image/upload/v1670431104/Brochures/2023.pdf" className="btn c4t-btn standard-margin-top white-text body-font thin-font full-width" download> Download 2023 Academic Calendar </a>
                  
                    </div>




<div className="col-12 col-md-9 mx-auto text-center sectional-margin-top nominate"> 

         <h2 className="body-font fat-font mobile-header-text">
       Teacher Nomination Form
       </h2>
       <div className="c4t-blue-bg hor-bar-blue">


       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 
       </div>



       <div className="col-12">

<div className='p-5 text-center'>
            <form className='col-12 col-md-6 mx-auto'>


                       <div classsName="col-12">
                            <h4 className='header-font'>  1. School Details </h4>
                        </div>


                    <label className='full-width black-text'>
                        Name of school
                    <input className='form-control' type="text" placeholder='Name of School' onChange={handleSchoolName} />

                        </label> 

                        <label className='full-width black-text'>
                       Address
                       <select className='form-control variantType' onChange={handleSchoolAddress} >
                        <option value=""></option>
                        <option value="Lagos"> Lagos </option>
                        <option value="Abuja"> Abuja </option>
                        <option value="Ogun"> Ogun </option>
                       
                       </select>
                        </label> 

                        <label className='full-width black-text'>
                       School Email
                        <input className='form-control' type="text" placeholder='School Email' onChange={handleSchoolEmail} />

                        </label> 

                        <label className='full-width black-text'>
                       School Website
                        <input className='form-control' type="text" placeholder='School Website' onChange={handleSchoolWebsite} />

                        </label> 

                        <div classsName="col-12">
                            <h4 className='header-font'> 2. Due Diligence </h4>
                        </div>

                        <label className='full-width black-text'>
                        Through what programme are you registering for the CPD plan ?
                        <select className='form-control variantType' onChange={handleSelectProgramme} >
                        <option value=""></option>
                        <option value="purchase"> Textbook purchase/pilot </option>
                        <option value="scholarship"> Code14 LABS Educator Scholarship Programme </option>
                        <option value="council"> International Literacy Day 2023 </option>
                       
                       </select>

                        </label> 

                    <label className='full-width black-text'>
                        Do you teach Computer Language/ Coding with Andrew Teaches Coding Interactive textbooks ?
                        <select className='form-control variantType' onChange={handleUseATC} >
                        <option value=""></option>
                        <option value="Yes"> Yes, we do </option>
                        <option value="No"> No, we do not </option>
                        <option value="Contact us"> We want to onboard Andrew Teaches Coding </option>
                       
                       </select>

                        </label> 


                        <div classsName="col-12">
                            <h4 className='header-font'> School Contact Person Details </h4>
                        </div>
                        <label className='full-width black-text'>
                       Title
                       <select className='form-control variantType' onChange={handleSchoolContactTitle} >
                        <option value="">Title </option>
                        <option value="Dr."> Dr </option>
                        <option value="Mr."> Mr </option>
                        <option value="Miss.">Miss </option>
                        <option value="Mrs.">Mrs </option>
                       </select>

                        </label> 

                       <label className='full-width black-text'>
                       Firstname
                       <input className='form-control' type="text" placeholder='First Name' onChange={handleSchoolContactFirstname} />
                       </label> 

                       <label className='full-width black-text'>
                       Lastname
                       <input className='form-control' type="text" placeholder='Last Name' onChange={handleSchoolContactLastname} />
                        </label> 

                       <label className='full-width black-text'>
                       Mobile No
                       <input className='form-control' type="number" placeholder='Mobile no' onChange={handleSchoolContactMobileNo} />
                        </label> 

                        <label className='full-width black-text'>
                       Email
                       <input className='form-control' type="email" placeholder='Email' onChange={handleSchoolContactEmail} />
                        </label> 

                        <label className='full-width black-text'>
                       Designation
                       <select className='form-control variantType' onChange={handleSchoolContactDesignation} >
                        <option value="">Select Designation </option>
                        <option value="Head of School"> Head of School </option>
                        <option value="Deputy Head of School"> Deputy Head of School </option>
                        <option value="Head of Curriculum"> Head of Curriculum  </option>
                        <option value="Executive Office"> Executive Director/CEO </option>
                       </select>
                        </label>

                        <div classsName="col-12">
                            <h4 className='header-font'>  3. Teacher Nomination </h4>
                        </div>

                       <label className='full-width black-text'>
                       How many teachers will you be registering ? 
                       <select className='form-control nomineeSize' onChange={handleTeacherNomination} >
                       <option value=""> Select nominees </option>
                        <option value="1"> 1 </option>
                        <option value="2">2 </option>
                       </select>
                       </label>

                       <div classsName="col-12">
                            <h4 className='header-font'> 3.1 Nominee Details </h4>
                        </div>

                        <div className="nominee1">

                        
                        <div classsName="col-12">
                            <h6 className='header-font'> 3.1.1 Nominee 1 </h6>
                        </div>


                        <label className='full-width black-text'>
                       Title
                       <select className='form-control variantType' onChange={handleSchoolNomineeTitle1} >
                        <option value="">Title </option>
                        <option value="Dr."> Dr </option>
                        <option value="Mr."> Mr </option>
                        <option value="Miss.">Miss </option>
                        <option value="Mrs.">Mrs </option>
                       </select>

                        </label> 

                       <label className='full-width black-text'>
                       Firstname
                       <input className='form-control' type="text" placeholder='First Name' onChange={handleSchoolNomineeFirstname1} />
                       </label> 

                       <label className='full-width black-text'>
                       Lastname
                       <input className='form-control' type="text" placeholder='Last Name' onChange={handleSchoolNomineeLastname1} />
                        </label> 

                       <label className='full-width black-text'>
                       Mobile No
                       <input className='form-control' type="number" placeholder='Mobile no' onChange={handleSchoolNomineeMobileno1} />
                        </label> 

                        <label className='full-width black-text'>
                       Email
                       <input className='form-control' type="email" placeholder='Email' onChange={handleSchoolNomineeEmail1} />
                        </label> 

                        <label className='full-width black-text'>
                       Date of birth
                       <input className='form-control' type="date" placeholder='Date of Birth' onChange={handleSchoolNomineeDOB1} />
                        </label>

                        <label className='full-width black-text'>
                     What subject does the nominee teach ?
                      <select className='form-control' onChange={handleSchoolNomineeSubjectStatus1} >
                        <option value=""> Select Subject  </option>
                        <option value={true}> ICT </option>
                        <option value={false}> Others </option>                       
                      </select>
                        </label>

                        <label className='full-width black-text'>
                      Coding Proficiency Level
                      <select className='form-control' onChange={handleSchoolNomineeProficiency1} >
                        <option value=""> Select Coding Proficiency level </option>
                        <option value="Beginner"> Beginner </option>
                        <option value="Intermediate"> Intemediate </option>
                        <option value="Expert"> Expert </option>                        
                      </select>
                        </label>

                    <label className='full-width black-text'>
                        Does the nominee own a computer ?
                   
                      <select className='form-control' onChange={handleSchoolNomineeComputerStatus1}>
                        <option value="">  </option>
                        <option value={true}> Yes </option>
                        <option value={false}> No </option>                       
                    </select>
                        </label>

                     <label className='full-width black-text'>
                     Is the Nominee registered with the TRCN ?
                      <select className='form-control' onChange={handleSchoolNomineeNtrcStatus1} >
                        <option value=""> Select Status </option>
                        <option value={true}> Yes </option>
                        <option value={false}> No  </option>                       
                      </select>
                        </label>


                    


                        </div>



                        <div className="nominee2">

                        
                        <div classsName="col-12">
                            <h6 className='header-font'> 3.1.2 Nominee 2 </h6>
                        </div>


                        <label className='full-width black-text'>
                       Title
                       <select className='form-control variantType' onChange={handleSchoolNomineeTitle2} >
                        <option value="none">Title </option>
                        <option value="Dr."> Dr </option>
                        <option value="Mr."> Mr </option>
                        <option value="Miss.">Miss </option>
                        <option value="Mrs.">Mrs </option>
                       </select>

                        </label> 

                       <label className='full-width black-text'>
                       Firstname
                       <input className='form-control' type="text" placeholder='First Name' onChange={handleSchoolNomineeFirstname2} />
                       </label> 

                       <label className='full-width black-text'>
                       Lastname
                       <input className='form-control' type="text" placeholder='Last Name' onChange={handleSchoolNomineeLastname2} />
                        </label> 

                       <label className='full-width black-text'>
                       Mobile No
                       <input className='form-control' type="number" placeholder='Mobile no' onChange={handleSchoolNomineeMobileno2} />
                        </label> 

                        <label className='full-width black-text'>
                       Email
                       <input className='form-control' type="email" placeholder='Email' onChange={handleSchoolNomineeEmail2} />
                        </label> 

                        <label className='full-width black-text'>
                       Date of Birth
                       <input className='form-control' type="date" placeholder='Date of Birth' onChange={handleSchoolNomineeDOB2} />
                        </label>

                        <label className='full-width black-text'>
                     What subject does the nominee teach ?
                      <select className='form-control' onChange={handleSchoolNomineeSubjectStatus2} >
                        <option value="none"> Select Subject  </option>
                        <option value={true}> ICT </option>
                        <option value={false}> Others </option>                       
                      </select>
                        </label>

                        <label className='full-width black-text'>
                      Coding Proficiency Level
                      <select className='form-control' onChange={handleSchoolNomineeProficiency2} >
                        <option value="none"> Select Coding Proficiency level </option>
                        <option value="Beginner"> Beginner </option>
                        <option value="Intermediate"> Intemediate </option>
                        <option value="Expert"> Expert </option>                        
                      </select>
                        </label>

                    <label className='full-width black-text'>
                        Does the nominee own a computer ?
                   
                      <select className='form-control' onChange={handleSchoolNomineeComputerStatus2} >
                        <option value="none">  </option>
                        <option value={true}> Yes </option>
                        <option value={false}> No </option>                       
                    </select>
                        </label>

                     <label className='full-width black-text'>
                     Is the Nominee registered with the TRCN ?
                      <select className='form-control' onChange={handleSchoolNomineeNtrcStatus2} >
                        <option value="none"> Select Status </option>
                        <option value={true}> Yes </option>
                        <option value={false}> No  </option>                       
                      </select>
                        </label>


                    

                        </div>


                    <div className='col-12 text-center'>
                    <Link To="#" onClick={submitCpdRegistrationObject} className="btn c4t-btn black-text body-font thin-font full-width"> Register Teacher Now </Link>
                    </div>
            </form>
        </div>

</div>


<Footer/>

<Modal2 show={loadingModalIsOpen}>
   
   <div className='row'>
       <div className='col-12 text-center'>

       </div>  
       </div>
  
   </Modal2>
</div>


</>




)


}

export default Training;