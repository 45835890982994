import logo from './logo.svg';
import './App.css';
import jquery from '../node_modules/jquery/dist/jquery.js'
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import home from './components/home.js';
import portal from './components/portal.js';
import products from './components/products.js';
import people from './components/people.js';
import safety from './components/safety.js';
import research from './components/research.js';
import teacherTraining from './components/training.js';
import exam from './components/baseline.js';
import cli from './components/cli.js';
import clf from './components/clf.js';
import clim from './components/clim.js';
import books from './components/books.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"

function App() {
  return (
     <Router>

       
      <Switch>
        <Route exact path="/" component={home} />
        <Route exact path="/research" component={research} />
        <Route exact path="/portal" component={portal} />
        <Route exact path="/products" component={products} />
        <Route exact path="/people" component={people} />
        <Route exact path="/safety" component={safety} />
        <Route exact path="/assess/assessments" component={exam}>
        </Route>
        <Route  exact path="/assess/assessments/literacy/cli-pathway" component={cli} />
        <Route exact path="/assess/assessments/literacy/clf-pathway" component={clf} />
        <Route exact path="/assess/assessments/employability/clim-pathway" component={clim} />
        <Route exact path="/teacher-training" component={teacherTraining} />
        <Route exact path="/andrewteachescoding/books" component={books} />

       </Switch>
 </Router>
  );
}

export default App;
