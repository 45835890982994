import react, {component} from "react";
import {Link} from 'react-router-dom';




const Footer = () =>{
    return(
        <>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row mt-5 text-white footer_row">
                        <div className="col-12 col-md-4 footer1 standard-margin-bottom">
                        <h5 className="body-font fat-font">About our Lab </h5>
                            <p>Code14 LABS is a research and development company focused on scaling education's most critical components through
                                      technology inclusion.
                            </p>
                        </div>
                        <div className="col-12 col-md-3 footer2 standard-margin-bottom">
                            <ul className="pOff">
                            <li>
                            <h5 className="body-font fat-font"> Quick Links </h5>
                            </li>
                            <a href="https://cs-labs.online" target="_blank"> 
                            <li>Teacher Training</li>
                            </a>
                            <Link to="/andrewteachescoding/books">
                            <li>Andrew Teaches Coding</li> 
                            </Link>
                            <Link to="/research">
                            <li>Computer language Research</li> 
                            </Link>
                            <Link to="/safety">
                               <li>Online Safety trainings</li>
                            </Link>
                            <Link to="/people#governance"> 
                            <li>Governance/Advisory Board</li>
                            </Link>
                            <Link to="/portal"> 
                            <li>Partner Portal</li>
                            </Link>
                             
                             
            
                            </ul>
                        </div>
                        <div className="col-12 col-md-2 footer3 standard-margin-bottom">
                        <h5 className="body-font fat-font"> Social Media </h5>
                        <a href="https://www.linkedin.com/company/code-4teen/"><i className="fa fa-linkedin"></i></a>
                            <a href="https://www.instagram.com/code_4teen_labs/"><i className="fa fa-instagram"></i></a>
                        </div>

                        <div className="col-12 col-md-3 footer3 standard-margin-bottom">
                        <h5 className="body-font fat-font"> Location  </h5>
                        <div class="mapouter"><div class="gmap_canvas"><iframe width="300" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=8%20connal%20road,%20Yaba&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-a.com"></a><a href="https://www.embedgooglemap.net"></a></div></div>
                        </div>
                    </div>
                </div>

                <div className="row full-width"> 
                <div className="col-12 offset-md-1 col-md-5 pOff">
                   <h6 className="body-font white-text small-font center "> Copyright Code Fourteen LLC 2021 </h6> 

                </div>

                <div className="col-12 offset-md-4 col-md-2 pOff">
                   <h6 className="body-font white-text small-font center"> Web Design & Content by iNspire </h6> 

                </div>
                
                </div>
            </div>
        </>
    )
}

export default Footer;