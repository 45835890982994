import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import landingBanner from '../images/team-banner.jpg';
import Navigation from './navigation.js';
import andrew from '../images/od.jpeg';
import trust from '../images/trust.jpg';
import aluko from '../images/aluko.jpg';
import busayo from '../images/busayo.jpg';
import bukola from '../images/bukola.jpg';
import chinenye from '../images/Chinenye.jpg';
import SL from '../images/SL.jpg';
import BL from '../images/BL.jpg';
import FA from '../images/FA.jpg';
import AO from '../images/AO.jpg';
import MO from '../images/MO.jpg';
import JA from '../images/JA.jpg';
import DA from '../images/DA.jpg';
import AO1 from '../images/AOO.jpg';
import mobBanner from '../images/people-banner-mob.jpg';
import Footer from '../components/footer';

class people extends Component { 


  constructor(props){
       super(props)
       this.state={

       }

     }


componentDidMount(){ 
    window.scrollTo(0, 0)
}


render(){


return(


<div className=""> 

    <Navigation />

        <div className="row container-fluid pOff mOff standard-margin-bottom">
            <div className="col-12 pOff top-banner  top-banner-dsk team-banner"> 

            </div>

            <div className="col-12 pOff top-banner top-banner-mob "> 
<img src={mobBanner} class="img-fluid" />
</div>

            <div className="col-12 text-center c4t-grey-bg standard-margin-bottom">
    <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 
      <h3 className="body-font white-text standard-margin-top"> " Great things are never done by one individual" </h3> 

     <h5 className="body-font white-text thin-font standard-margin-top standard-margin-bottom"> 
       Our mission at code 4TEEN Labs is been driven by a diverse team made up of curriculum, product, software and strategy professionals, backed by a seasoned
       advisory board comprised of tested executives across Education, Finance, ICT and Sales. 
     </h5> 

     </div>
    </div> 
        </div>  

  
      
  <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="">
  <button className="nav-link body-font fat-text c4t-grey service-pill d-block d-sm-none active" id="v-pills-profile-tab-mob" data-bs-toggle="pill" data-bs-target="#v-pills-profile-mob" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Governance  </button>
   {/*----------
  <button className="nav-link body-font fat-text c4t-grey service-pill d-block d-sm-none" id="v-pills-home-tab-mob" data-bs-toggle="pill" data-bs-target="#v-pills-home-mob" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">   Mission team  </button>
    ---------*/}   
  </div>
  
         <div className="d-flex align-items-start">
  <div className="nav flex-column nav-pills me-5" id="v-pills-tab" role="tablist" aria-orientation="">
  <button className="nav-link body-font fat-text c4t-grey service-pill d-none d-sm-block active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Governance  </button>
     {/*----------
  <button className="nav-link body-font fat-text c4t-grey service-pill d-none d-sm-block" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">   Mission team  </button>
        ---------*/} 
  </div>

  <div className="tab-content d-none d-sm-block pOff" id="v-pills-tabContent">
    <div className=" tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    
    <div className="row full-width">

                        {/*----------Trust---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={trust} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h2 className="body-font thin-font">
                                          Trust 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Ekama <span className="small-font"></span>
                                    </h2>
                                    <p className="body-font">Head, Curriculum Integration </p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                     {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={trust} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Trust Ekama</h5>
                                                        <small>Head, Curriculum Integration </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Trust Ekama (ph.d in view)</h2>
                                                            <p>Trust was an assistant lecturer in the department of chemistry, university of Lagos(UNILAG). 
                                                                He is highly motivated and innovative, with an undying quest for acquiring knowledge for personal growth and capacity building.
                                                                Trust brings his understanding of structured academics at the highest level to code 4TEEN Labs and our mission. Trust works closely with the 
                                                                executive director on the Measurement, Evaluation, Learning and Optimization component of code 4TEEN's research, contributing to the 
                                                                continuous redesign of our curriculum development process. Trust is currently completing a doctorate degree in computational chemistry.
    
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}

                                </div>
                            </div>
                        </div>
            
                        {/*-----------Ifeoluwa------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={aluko} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Ifeoluwa
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Aluko
                                    </h2>
                                    <p className="body-font">Strategic Ops, Office of the ED</p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                       {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={aluko} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Ifeoluwa Aluko</h5>
                                                        <small>Strategic Ops, Office of the ED</small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Ifeoluwa Aluko</h2>
                                                            <p>Ifeoluwa, through iNspire, supports the leadership at code 4TEEN in implmentation and monitoring of the labs research and outreach programmes with education stakeholders. 
                                                                On a day to day basis, Ifeoluwa is tasked with ensuring all operational factors required for smooth delivery of project objectives are available to both internal and external coordinators, 
                                                                this is done in conjunction with the product success team.   
                                                                 </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>
                        {/*-----------Bukola------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={bukola} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Bukola
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Bankole
                                    </h2>
                                    <p className="body-font"> Lead, Product Success</p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     
                                    {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={bukola} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Bukola Bankole</h5>
                                                        <small>Product Success </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Bukola Bankole</h2>
                                                            <p>  Bukola Bankole is the lead relationship manager at code 4TEEN Labs, ensuring all engagements entered into by the lab are converted into successful outcomes.
                                                                 Through the product success department she coordinates the onboarding processes,in conjunction with the operations team to deliver value to all users of the 
                                                                 labs software products. Bukola has communications skills and a natural flair for achieving
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>

                                                {/*-----------chinenye------------*/}
                                                <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={chinenye} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                          Chinenye
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                          Okonkwo
                                    </h2>
                                    <p className="body-font"> Customer Relations - Abuja </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     
                                    {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#chinenye">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="chinenye" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={chinenye} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Chinenye Okonkwo</h5>
                                                        <small>Customer Relations - Abuja </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Chinenye Okonkwo</h2>
                                                            <p> 
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>


                    </div>

    </div>
    
        {/*-----------advisory board------------*/}
    <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
         
    <div className="row">
                {/*----------andrew---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={SL} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">

                                    <h2 className="body-font thin-font">
                                           Olusakin
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                          Labeodan
                                    </h2>
                                    
                                    <p className="body-font">Chairman</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/olusakin-labeodan-43235924/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>


                                </div>
                            </div>
                        </div>
                        {/*----------Trust---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={BL} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h2 className="body-font thin-font">
                                          Bunmi 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Lawson
                                    </h2>
                                    <p className="body-font"> Opportunities & Growth </p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/bunmilawson/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>


                                </div>
                            </div>
                        </div>

                          {/*-----------Ifeoluwa------------*/}
                          <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={FA} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                         Olufemi
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                        Adeagbo
                                    </h2>
                                    <p className="body-font"> Product Strategy </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/olufemi-adeagbo-a0664444/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>


                         {/*----------Busayo---------*/}
      
                        {/*-----------Ifeoluwa------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={AO1} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Antonia
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                          Omonze
                                    </h2>
                                    <p className="body-font">Education Business</p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/antonia-omonze-a2351940/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        {/*-----------Bukola------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={DA} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           David 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Alao
                                    </h2>
                                    <p className="body-font">  Opportunities & Growth </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/david-alao-21a0a127/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        
                        
                        {/*-----------Mark Oguh------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={MO} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Mark
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Oguh
                                    </h2>
                                    <p className="body-font"> Opportunities & Growth </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/mark-oguh-mba-acti-fca-3745a438/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>

                                                        {/*----------andrew---------*/}
                                                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={andrew}  alt=""/>
                                </div>
                                <div className="col-12 col-md-6">

                                    <h2 className="body-font thin-font">
                                           Otaru 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Daudu
                                    </h2>
                                    
                                    <p className="body-font">Executive Director- Research & Automation</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                     <a href="https://www.linkedin.com/in/otaru-daudu-2068b67b/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        
            
                        
                        
                    
                    
                    </div>
 
    
    
    </div>
      </div>
      </div>
      
      
      
      
      
      
      
      {/*-------mobile---------*/}
      
      
        <div className="row full-width standard-padding-rl-mobile">
        
          {/*-------mission team---------*/}
      <div class="tab-content d-block d-sm-none " id="v-pills-tabContent">
      <div class="tab-pane fade" id="v-pills-home-mob" role="tabpanel" aria-labelledby="v-pills-home-tab">
      
      
      
      <div className="row">
                {/*----------andrew---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img  src={andrew} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">

                                    <h2 className="body-font thin-font">
                                           Otaru 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Daudu
                                    </h2>
                                    
                                    <p className="body-font">Executive Director- Research & Automation</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 


                                     {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#oaodbiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="oaodbiomob" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content text-center">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={andrew} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Otaru Daudu</h5>
                                                        <small>Founder </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Otaru Daudu</h2>
                                                            <p> Otaru focuses on curriculum research, product development and automation services at code 4TEEN Labs. He leads the engineering practice on a daily basis to expand the labs infrastructure and content products.
                                                                Otaru is custodian of the labs education technology vision and advocacy drive. As a digital entrepreneur OAOD has interests across marketing and technology through his media outfit iNspire. 
                                                                OAOD is full-stack software engineer and globally certified digital marketer with a keen interest for interactive technologies. </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn c4t-btn" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}

                                </div>
                            </div>
                        </div>
                        {/*----------Trust---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={trust} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h2 className="body-font thin-font">
                                          Trust 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Ekama <span className="small-font"> </span>
                                    </h2>
                                    <p className="body-font">Head, Curriculum Integration R&D</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                                  <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#trustbiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="trustbiomob" tabindex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={trust} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Trust Ekama</h5>
                                                        <small>Head, Curriculum Integration </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Trust Ekama (ph.d in view)</h2>
                                                            <p>Trust was an assistant lecturer in the department of chemistry, university of Lagos(UNILAG). 
                                                                He is highly motivated and innovative, with an undying quest for acquiring knowledge for personal growth and capacity building.
                                                                Trust brings his understanding of structured academics at the highest level to code 4TEEN Labs and our mission. Trust works closely with the 
                                                                executive director on the Measurement, Evaluation, Learning and Optimization component of code 4TEEN's research, contributing to the 
                                                                continuous redesign of our curriculum development process. Trust is currently completing a doctorate degree in computational chemistry.
    
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>
                         {/*----------Busayo---------*/}
                         <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={busayo} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Omotuka
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Busayo
                                    </h2>
                                    <p className="body-font">Lead, Platforms & Capacity</p>

                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                       {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                                  <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#busayobiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="busayobiomob" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={busayo} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Omotuka Busayo</h5>
                                                        <small>Lead, Platforms & Capacity</small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Omotuka Busayo</h2>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                                            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                                            numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                                            optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
                                                            obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
                                                            nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>


                                       
                        </div>
                        {/*-----------Ifeoluwa------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={aluko} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Ifeoluwa
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Aluko
                                    </h2>
                                    <p className="body-font">Strategy & Ops. Office of the ED</p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 


                                       {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#ifebiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="ifebiomob" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={aluko} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Ifeoluwa Aluko</h5>
                                                        <small>Strategic Ops, Office of the ED</small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Ifeoluwa Aluko</h2>
                                                            <p>Ifeoluwa, through iNspire, supports the leadership at code 4TEEN in implmentation and monitoring of the labs research and outreach programmes with education stakeholders. 
                                                                On a day to day basis, Ifeoluwa is tasked with ensuring all operational factors required for smooth delivery of project objectives are available to both internal and external coordinators, 
                                                                this is done in conjunction with the product success team.   
                                                                 </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>
                        {/*-----------Bukola------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={bukola} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Bukola
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Bankole
                                    </h2>
                                    <p className="body-font"> Product Success & Relationships </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                      {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#bukolabiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="bukolabiomob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={bukola} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Bukola Bankole</h5>
                                                        <small>Product Success </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Bukola Bankole</h2>
                                                            <p>  Bukola Bankole is the lead relationship manager at code 4TEEN Labs, ensuring all engagements entered into by the lab are converted into successful outcomes.
                                                                 Through the product success department she coordinates the onboarding processes,in conjunction with the operations team to deliver value to all users of the 
                                                                 labs software products. Bukola has communications skills and a natural flair for achieving
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img src={chinenye} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                          Chinenye
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Okonkwo
                                    </h2>
                                    <p className="body-font">  Customer Relationship Associate - Abuja </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                     {/*Modal Start*/}

                                                  {/*Button trigger modal*/}
                                    <button type="button" class="btn c4t-btn" data-bs-toggle="modal" data-bs-target="#chinenyebiomob">
                                        View profile
                                    </button>

                                    {/*Modal*/}
                                    <div class="modal fade" id="chinenyebiomob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                        <div class="modal-header row text-center">
                                                <div class="col-12 col-md-6">
                                                    <div className="col-12">
                                                        <img src={chinenye} alt=""/>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <h5>Chinenye Okonkwo</h5>
                                                        <small>Customer Relations - Abuja </small>
                                                    </div>
                                                </div>
                                                    <div class="col-12 col-md-6 modal-body tex-center">
                                                            <h2>Meet Chinenye Okonkwo</h2>
                                                            <p> 
                                                            </p>
                                                    </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    {/*Modal end*/}
                                </div>
                            </div>
                        </div>
                    </div>
      
      
      </div>
      
      
      
        {/*-------advisory board---------*/}
      
      <div className="tab-pane fade show active" id="v-pills-profile-mob" role="tabpanel" aria-labelledby="v-pills-profile-tab">
<div className="row">
                {/*----------andrew---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={SL} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">

                                    <h2 className="body-font thin-font">
                                           Olusakin
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                          Labeodan
                                    </h2>
                                    
                                    <p className="body-font">Chairman</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/olusakin-labeodan-43235924/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>


                                </div>
                            </div>
                        </div>
                        {/*----------Trust---------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={BL} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h2 className="body-font thin-font">
                                          Bunmi 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Lawson
                                    </h2>
                                    <p className="body-font"> Opportunities & Growth </p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/bunmilawson/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>


                                </div>
                            </div>
                        </div>

                          {/*-----------Ifeoluwa------------*/}
                          <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={FA} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                         Olufemi
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                        Adeagbo
                                    </h2>
                                    <p className="body-font"> Product Strategy </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/olufemi-adeagbo-a0664444/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>


                         {/*----------Busayo---------*/}
      
                        {/*-----------Ifeoluwa------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={AO1} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Antonia
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                          Omonze
                                    </h2>
                                    <p className="body-font">Education Business</p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/antonia-omonze-a2351940/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        {/*-----------Bukola------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={DA} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           David 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Alao
                                    </h2>
                                    <p className="body-font">  Opportunities & Growth </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/david-alao-21a0a127/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        
                        
                        {/*-----------Mark Oguh------------*/}
                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={MO} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">
                                     <h2 className="body-font thin-font">
                                           Mark
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Oguh
                                    </h2>
                                    <p className="body-font"> Opportunities & Growth </p>

                                    <div className="c4t-blue-bg hor-bar-blue">

                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 
                                     <a href="https://www.linkedin.com/in/mark-oguh-mba-acti-fca-3745a438/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>

                                                        {/*----------andrew---------*/}
                                                        <div className="col-12 col-md-6 col-mb standard-margin-bottom">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="bookcover" src={andrew} alt=""/>
                                </div>
                                <div className="col-12 col-md-6">

                                    <h2 className="body-font thin-font">
                                           Otaru 
                                    </h2>  

                                    <h2 className="body-font fat-font">
                                           Daudu
                                    </h2>
                                    
                                    <p className="body-font">Executive Director- Research & Automation</p>
                                    <div className="c4t-blue-bg hor-bar-blue">


                                     </div> 

                                    <div className="c4t-yellow-bg hor-bar-yellow">


                                     </div> 

                                     <a href="https://www.linkedin.com/in/otaru-daudu-2068b67b/"><i className="fa fa-linkedin black-text visual-padding-1 fat-text"></i></a>

                                </div>
                            </div>
                        </div>
                        
            
                        
                        
                    
                    
                    </div>
 

 
  
      
      </div>
      
     </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

</div>
<Footer/>
</div>


    )



}

}

export default people;
