import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import researchBanner from '../images/research-banner-mob.jpg';
import $ from '../../node_modules/jquery/dist/jquery.js';
import ssseb from '../images/Logo.jpg';
import lagoon from '../images/lagoon.png';
import prioryPrep from '../images/priory.png';
import grandmates from '../images/gmates.png';
import citypride from '../images/CPS.png';
import tunwase from '../images/tunwase.jpeg';
import fountain from '../images/fs.jpg';
import greensprings from '../images/Greensprings-1.jpg';
import beesknees from '../images/Beesknees.png';
import circularLearning from '../images/circular-learning.jpg';
import Navigation from './navigation.js';
import solutions from '../images/solutions-framework.jpg';
import progressiveLearning from '../images/progressive-learning.jpg';
import schoolPartnerships from '../images/school-partnership.jpg';
import infrastrucutre from '../images/infrastrucutre.jpg';
import pedagogy from '../images/Pedagogy-content.jpg';
import evidence from '../images/Evidence.jpg';
import cdp from '../images/cdp.jpg';
import Fariah from '../images/fariah.jpeg';
import bgs from '../images/bgs.jpeg';
import Footer from '../components/footer';





class Research extends Component { 


  constructor(props){
       super(props)
       this.state={

       }


     }


componentDidMount(){ 
  window.scrollTo(0, 0)

  $('#homepageslidedsk, #homepageslidemob').carousel({
    interval: 3000,
    pause:false,
    ride: false
   })

}




render(){


  return(

<div className=""> 

<Navigation />

<div className="row container-fluid   pOff mOff">
          <div className="col-12 top-banner research-banner top-banner-dsk pOff"> 

            </div>
            <div className="col-12 top-banner top-banner-mob pOff"> 
<img class="img-fluid" src={researchBanner} />
</div>

            <div className="col-12 text-center c4t-white-bg standard-margin-bottom pOff">
    <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 
      <h3 className="body-font standard-margin-top"> Computer language As A Subject - Pedagogy / Syllabus / Infrastructure </h3> 

     <h5 className="body-font thin-font standard-margin-top standard-margin-bottom"> 
    Fluency in Computer languages has become more critical moving into a digital first world, even more so for the next generation 
    workforce. Creating a system that ensures equitable access to computer language as exists for Math and English at scale is paramount to ensure the next generation workforce from Africa can compete in markets of the future. <br/> <br/>
    To achieve this, Code 4TEEN Labs is committed to developing a sustainable curriculum integration model covering; Pedagogy, Scheme Of Work and Curriculum Delivery Infrastructure which can be absorbed into the educational system, taking 
    advantage of the depth of reach within the educational system to upskill the workforce of the next generation in the shortest time possible. 
     </h5> 

     </div>
    </div>
       </div> 




<div className="container ">
   


   {/*-------end of row ---------*/}
   
   
   <div className="row sectional-margin-top ">
   
   <div className="col-12 col-md-6 sectional-margin-top-visual">
   
   <img src={circularLearning} width="100%" />
   
   </div>
   <div className="col-12 col-md-6 sectional-margin-top">
    <h2 className="body-font thin-font mobile-header-text">
     Identification of
    </h2>  
   
      <h2 className="body-font fat-font mobile-header-text">
      
       Research problem 
    </h2>
    <div className="c4t-blue-bg hor-bar-blue">
   
   
    </div> 
   
    <div className="c4t-yellow-bg hor-bar-yellow">
   
   
    </div> 
   
   <p className="body-font thin-font"> 
    Children of all ages are being either engaged in school or at home to learn some form of 
    coding. Although there is a unified consensus on the need to for students to embrace this new level of fluency 
    no singluar approach for creating standardized learning outcomes has been truely validated. <br/> <br/> At the primary level, Computing teachers are constrained to 
    using curriculums which do not provide a clear enough preface or do not cascade forward in a progressive format long enough to become engrained in their learners minds. 
    This is embodied in what we define as a 'circular' curriculum which revolves around an area of concern continously.
    
   
   </p>
   <Link onClick={this.slideToWork} className="btn c4t-btn black-text body-font thin-font full-width"> Read our Solution </Link>
   
   
   
   </div>
   
   
   
   </div> 
   
{/*-------end of row ---------*/}



   {/*-------end of row ---------*/}
   
   
   <div className="row sectional-margin-top ">

   <div className="col-12 col-md-6 sectional-margin-top">
    <h2 className="body-font thin-font mobile-header-text">
    Defining our
    </h2>  
   
      <h2 className="body-font fat-font mobile-header-text">
      
       Solutions approach
    </h2>
    <div className="c4t-blue-bg hor-bar-blue">
   
   
    </div> 
   
    <div className="c4t-yellow-bg hor-bar-yellow">
   
   
    </div> 
   
   <p className="body-font thin-font"> 
      In solving the identified Problem, Code 4TEEN carries out field research in a bid to create an evidence-led scheme of work 
      education stakeholder can depend on for infusing text-based code education into their school system's. The goal; To help computing teachers breakout of the
      dominating circular learning pattern as well as cheapen the cost of access to knowledge. <br/> <br/> To achieve this, our labs establish a continuing partnership with trusted educational institutions
      to validate curriculum & pedagogy in realtime class scenarios to develop both a trusted scheme of work and science for teaching computer language/coding. To ensure quantitative data can be tracked and qualitative data stored, 
      a curriculum delivery infrastructure serves as the main input and collaboration tool between students and their Computing teachers.
    
   
   </p>
   <Link onClick={this.slideToWork} className="btn c4t-btn black-text body-font thin-font full-width"> See equitable access Model </Link>
   
   
   
   </div>
   

   <div className="col-12 col-md-6 sectional-margin-top-visual">
   
       <img src={progressiveLearning} width="100%" />
   
   </div>


   
   
   
   </div> 
   
{/*-------end of row ---------*/}

{/*-------start of row ---------*/}

<div className="sectional-margin-top text-center ">

<div className="col-12 sectional-margin-top-visual text-center" id="">
     <h3 className="header-text"> Equitable Access and Sustainable Integration Model </h3>
   
        <img src={solutions} width="100%" />
   
   </div>



    <div className="row standard-margi-bottom">


  <div className="card col-12 col-md-4 pOff standard-margin-bottom">
  <img src={schoolPartnerships} className="card-img-top" alt="..." />
  <div className="card-body c4t-black-bg">
    <h5 className="card-title body-font white-text"> Education stakeholder partnerships</h5>
    <p className="card-text white-text"> Code 4TEEN Labs creates academic partnerships with private and public education providers to build and distribute evidence-led computer language curriculum</p>
 
  </div>
</div>
    
<div className="card col-12 col-md-4 pOff standard-margin-bottom">
  <img src={infrastrucutre} className="card-img-top" alt="..." />
  <div className="card-body c4t-black-bg">
    <h5 className="card-title body-font white-text"> Curriculum Delivery Infrastructure</h5>
    <p className="card-text white-text"> For ease of distribution and remote access to quantitative and qualitative data generated by learners and teachers as they collaborate, Code 4TEEN Labs provisions a web based platform for curriculum delivery and data storage.</p>
 
  </div>
</div>

<div className="card col-12 col-md-4 pOff standard-margin-bottom">
  <img src={pedagogy} className="card-img-top" alt="..." />
  <div className="card-body c4t-black-bg">
    <h5 className="card-title body-font white-text"> Pedagogy & Curriculum Implementation </h5>
    <p className="card-text white-text">Intial class facilitation is carried out by curriculum integration specialist at code 4TEEN Labs in conjunction with a nominated computing teacher under Pedagogy mentorship. Implementation begins at the defined entry level i.e Grade 4 profressively till Grade 6</p>
 
  </div>
</div>

<div className="card col-12 col-md-4 pOff standard-margin-bottom">
  <img src={evidence} className="card-img-top" alt="..." />
  <div className="card-body c4t-black-bg">
    <h5 className="card-title body-font white-text"> Evidence-Led Syllabus Optimization </h5>
    <p className="card-text white-text"> Quantitative data generated from useage by students, cdrives the continous refinement of syllabus content and pedagogy .</p>
 
  </div>

</div>

<div className="card col-12 col-md-4 pOff standard-margin-bottom">
  <img src={cdp} className="card-img-top" alt="..." />
  <div className="card-body c4t-black-bg">
    <h5 className="card-title body-font white-text"> Continous Development Trainings </h5>
    <p className="card-text white-text">Resident Computing teacher via mentorship and training is empowered to take 100% control of curriculum delivery</p>
 
  </div>
</div>



</div>
    

  <div className="row sectional-margin-top">


         <h3 className="header-text"> Some trusted names in education integrating the Equitable Access Model </h3>


      <div className="col-12 col-md-3">
       <img src={greensprings} className="card-img-top img-fluid" alt="..." width="30%"/>
     </div>

      <div className="col-12 col-md-3">
       <img src={fountain} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

       <div className="col-12 col-md-3">
       <img src={beesknees} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={Fariah} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={bgs} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={lagoon} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={grandmates} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={citypride} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={prioryPrep} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

     <div className="col-12 col-md-3">
       <img src={tunwase} className="card-img-top img-fluid" alt="..." width="30%" />
     </div>

  </div>


</div>
   




   </div>
   {/*-------end of row ---------*/}

<Footer />
   </div> 



  )
}

}
export default Research;