import React, { Component, useState, useEffect  } from 'react';
import {Route, Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import axios from 'axios';
import {read, utils} from 'xlsx';
import Modal2 from 'react-bootstrap/Modal';
import Modal3 from 'react-bootstrap/Modal';
import jQuery from 'jquery';
import secondLogo from '../images/c4t.png';
import landingBanner from '../images/safety.jpg';
import $ from 'jquery/dist/jquery.js';
import Navigation from './navigation.js';
import pedagogy from '../images/pedagogy.jpg';
import chrisland from '../images/chrisland.png';
import greensprings from '../images/Greensprings-1.jpg';
import fountain from '../images/fs.jpg';
import beesknees from '../images/Beesknees.png';
import kidscourt from '../images/kid.png';
import rainbow from '../images/rainbow.png';
import pampers from '../images/pampers.jpg';
import safetyBannerMob from '../images/safety-banner-mob.jpg';
import Footer from '../components/footer';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import content from '../images/content.png';
import idea from '../images/idea.png';
import syntax from '../images/syntax.png';
import html from '../images/html.png';
import javascript from '../images/js.png';
import close from '../images/close.png';

const Clim = () => {

    const [forms, setForms] = useState(false);
    const [schoolLogo, setSchoolLogo] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [xcelRows, setXcelRows] = useState([]);
    const [loadingModalIsOpen, setloadingModalIsOpen] = useState(false);
    
 
    const requiredFields = ["firstname", "lastname", "class"];
    window.scrollTo(0, 0)
 

async  function Imageupload(e) {

    // Initiate upload
  var myWidget = window.cloudinary.createUploadWidget({
  cloudName: 'www-code4teen-tech', 
  uploadPreset: 'csizcotf'}, (error, result) => { 
  if (!error && result && result.event === "success") { 
    console.log('Done! Here is the image info: ', result.info.secure_url); 
      setSchoolLogo(result.info.secure_url)
    
  }
  }
  )
  
  document.getElementById("upload_widget").addEventListener("click", function(e){
    e.preventDefault()
  myWidget.open();
  }, false);
  
  }

 async function openRegister(){ 
      await  setForms(true)
        Imageupload()
  }


 async function closeRegister(){ 
    await  setForms(false)

}

  async function showForm2(e){ 
    
     jQuery('#form1').hide('fast', ()=> { 
        
       jQuery('#form2').show('fast')
     })
  }



  let { register, handleSubmit, watch, formState: { errors } } = useForm()
  let onSubmit = async function (data){
    console.log(xcelRows)
    const finalData ={ 
      initial: data,
      students: xcelRows
    }
      console.log(finalData)
        setForms(false)
        setloadingModalIsOpen(true)



axios
.post("https://cslabsapi-b05a5c3c41f0.herokuapp.com/createschoolviaapi", finalData , {
headers: {
"Content-Type": "application/json",
},
})
.then((res) => {
alert(res);
setloadingModalIsOpen(false)
})
.catch((err) => {
setForms(true)
alert(err);
console.log(err);
});



};
   let excelRows

    const readUploadFile = (e) => {
      e.preventDefault();

     
      if (e.target.files) {
        const file = e.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          excelRows = json

          setXcelRows(excelRows)
          
          validateData()
          console.log(json);
        };
        reader.readAsArrayBuffer(file);
 
   }

 
    };

    let completeRegistrationButton = document.getElementById('registration')


    const validateData = async () => {
      try {
      
        const firstItemKeys = excelRows;
        console.log(firstItemKeys)
  
        let requiredValidation = false;
  
        if (firstItemKeys.length) {
          firstItemKeys.forEach((element) => {
            console.log(element)
            if (!element.firstname || !element.lastname|| !element.class) {
              requiredValidation = true;
            }
          });
        }
  
        if (requiredValidation) {
          alert("Required fields " + JSON.stringify(requiredFields));
         
          jQuery(completeRegistrationButton).removeClass("fufil")
          jQuery(completeRegistrationButton).addClass("disabled")
          return;
         
        }

        jQuery(completeRegistrationButton).addClass("fufil")
        jQuery(completeRegistrationButton).removeClass("disabled")

       alert('candidate data valid')
       
        ``  
       return 
      } catch (error) {
        console.log("uploadData error: ", error);
      }
    };


	return(

<div className=""> 

   <Navigation />


   <div className="col-12 employmentBg standard-padding standard-margin-bottom standard-padding-mobile-top"> 
        <div className="col-12 col-md-5 standard-padding-top-bottom yellow-block-bg ">
          <div className='flex-container'>
            <div>
                <Card className="card3" style={{ width: '6rem' }}>
                <Card.Body className="text-center">
                <img src={idea} className="img-fluid" width="80%" /> 
                <Card.Title>CLI-2 </Card.Title>
                </Card.Body>
                 </Card>
                </div>
            <div>
               <h6 className='body-font standard-margin-top white-text mOff'> Employability Pathway</h6>
               <h3 className='body-font white-text mobile-test-heading'>Computer Language Implementation Test </h3>
            </div>
           
            </div>
         
          <h4 className="body-font white-text standard-margin-top"> Specifications </h4>
           
          <table className='standard-margin-top'>
              <tr>
              <th className="white-text" width="20%">Age</th>
              <th className="white-text" width="35%">Assessment</th>
              <th className="white-text" width="35%">Sections</th>
              <th className="white-text" width="10%">Duration</th>
             </tr>
            <tr>
              <td className="white-text"> 13 - 17 </td>
              <td className="white-text">Baseline <br/> Digital <br/> Curriculum independent </td>
              <td className="white-text"> Solution analysis<br/> Critical Thinking <br/> Problem Solving  </td>
              <td className="white-text">90mins</td>
            </tr>
          
          </table>

            <a  href="tel:+2348161682957"className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Buy CLI-2 Test For $150.00 </a>
            <Link to="/assess/assessments" className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Back to Assessment Center </Link>

        </div>
        </div>


     <div className="literacyExplain col-12 col-md-9 mx-auto text-center standard-margin-top standard-margin-bottom ">
                

                <h2 className="header-font"> Why CLI-2 ? </h2>
                
                
                 <p className='body-font'>
                       Being able to create economic value from digital skills is just as important as acquring digital skills. Computer Language Implementation Test (CLI-2) provides 
                       the platform for linking your students skill development journey to real Jobs while still in school. 
                       <br/>
                       <br/>
                       Exceling at CLI-2 gives a student access to summer job slots with companys who are looking to hire and groom future engineering talent. By consistenly getting placements into real engineering 
                       teams throughout highschool years, your students build up industry required credbility to access their first full time role soon after graduating college. The CLI-2 Verification Status can also be used to support immigration applications by students
                       under the global talent pathway.

                  </p>

        </div> 

      <div className="row supportedLang2 full-width mOff">



    
      <div className="col-12 col-md-6 standard-margin-top standard-margin-bottom standard-padding-tb-rl">
                

                <h5 className="header-font standard-margin-top"> Benefits of Verifying Employability with CLI-2  </h5>
                


                <ul> 
                <li> Get access to real world working exprience in engineering team  </li>  
                    <li> Improve chances of accessing scholarship to tertiary education opportunities through verification of your skillset </li>
                    <li> Confirm your status in the 1% of youth talent who are viable for their first real Job before 17years  </li>
                    <li> Get access to supercharged engineering mentorship to convert you into a software engineer </li>
  
                   
                </ul>
       
                </div>


                <div className="col-12 col-md-6  text-center standard-margin-top standard-margin-bottom ">

                <iframe width="100%" height="315" src="https://www.youtube.com/embed/Mmo8P-284_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
                </div>
        

    
   

        </div> 

        <div className="col-12 col-md-5 mx-auto text-center standard-margin-top standard-margin-bottom ">
                

                <h5 className="header-font standard-margin-top"> Computer Languages Supported by CLI-2  </h5>
                

            <div className='row'>
                <div className='col-md-6 text-center'> 
                <img src={html} className="img-fluid" width="90%" />
                </div>
                <div className='col-md-6 text-center'> 
                <img src={javascript} className="img-fluid" width="60%" />
                </div>
                     

                </div> 
                </div>


        <div className="row full-width mOff">
        <div className="col-12 startBg pOff"> 
        <div className="col-12 offset-md-5 col-md-7 standard-padding-top-bottom yellow-block-bg ">
         
           <h3 className='body-font white-text'> <b> Ready To Get Started With CLI-2 ?</b>  </h3>

           <h5 className="white-text standard-margin-top standard-margin-bottom">
           If you are a new school customer, create a your CODE Learner performance portal to subscribe for
           your CLI-2 Assessment.
          </h5>


                   <Link To="#" onClick={openRegister} className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Get Started </Link>


        <h5 className="white-text standard-margin-top standard-margin-bottom">
           If you are a parent, kindly give us a phone call to schedule an online assessment date!
          </h5>


          <a  href="tel:+2348161682957"className="btn c4t-white-btn white-text body-font standard-margin-top thin-font full-width standard-margin-bottom"> Talk to an expert </a>

        </div>
        </div>

        </div>


<Modal2 show={forms}>
   
   <div className='row standard-padding'>
    <div className="col-12 text-right"> 
        <img src={close} onClick={closeRegister} className="img-fluid" width="5%"  />
    </div>
   <div className='col-12 text-center'>
      <img src={secondLogo} className="img-fluid assess-logo"/> 
     <div className="col-12"> 
         <h4 className="head-text-mob"> Baseline Assessment Registration </h4>
         <h6 className="body-font standard-margin-bottom body-text-mob"> Completing this registration will <span className="c4t-red"> set up your learner performance management portal </span> automatically </h6>

     </div>

   <form id="forms" onSubmit={handleSubmit(onSubmit)}>
    

     <div className="col-12" id="form1">   


        <h6 className="head-text-mob"> Dear Applicant,</h6>
         <h5 className="body-font body-text-mob"> You will need the details of your school candidates to complete the process. Please download the excel sheet and fill out all candidates using 
           the columns as a guide. You will be required to upload this sheet at the candidate details section of the form.
         </h5>

         <a href="https://res.cloudinary.com/code4teen-tech/raw/upload/v1685885661/form%20downloads/Candidate_Details_Sample.xlsx" onClick={showForm2} className="btn body-font thin-font c4t-green-btn white-text standard-margin-top standard-margin-bottom"> Download Candidate Sheet </a>

     </div>
     

     <div className="col-12" id="form2">

     <div classsName="col-12">
           <h4 className='header-font head-text-mob'> School Details </h4>
           <h6 className="body-font c4t-grey"> 1/5</h6>
        </div> 
        {errors.schoolname?.type === 'required' && <p  className="c4t-red" role="alert">Tell us the name of your school </p>}
     <label className='full-width black-text'>
                 School name
                <input className='form-control' type="text" placeholder='School Name' name="schoolname"  {...register("schoolname", {required:true})} aria-invalid={errors.schoolname ? "true" : "false"}  />
                </label> 
                {errors.logo?.type === 'required' && <p  className="c4t-red" role="alert">Upload your schools Logo</p>}
                <label className='full-width black-text'>
                 School Logo
                <button id="upload_widget" className='btn btn-outline-success'> Upload your school logo </button>
                <input type="text" value={schoolLogo} name="logo"  {...register("logo", {required:true})} aria-invalid={errors.logo ? "true" : "false"} />
      </label> 
      {errors.schoolwebsite?.type === 'required' && <p  className="c4t-red" role="alert">Add your schools website address</p>}
               
      <label className='full-width black-text'>
                 School Website
                <input className='form-control' type="text" placeholder='School Website' name="schoolwebsite"  {...register("schoolwebsite", {required:true})} aria-invalid={errors.schoolwebsite ? "true" : "false"}  />
                </label> 
   
    

              

                <div classsName="col-12">
                     <h4 className='header-font head-text-mob'> School Contact Person Details </h4>
                     <h6 className="body-font c4t-grey"> 2/5</h6>
                 </div>
                 {errors.reptitle?.type === 'required' && <p  className="c4t-red" role="alert">Insert your title</p>}
    
                 <label className='full-width black-text'>
                  Title
                <select className='form-control variantType' name="reptitle"  {...register("reptitle", {required:true})} aria-invalid={errors.reptitle ? "true" : "false"}  >
                 <option value="">Title </option>
                 <option value="Dr."> Dr </option>
                 <option value="Mr."> Mr </option>
                 <option value="Miss.">Miss </option>
                 <option value="Mrs.">Mrs </option>
                </select>

                 </label> 
                 {errors.firstname?.type === 'required' && <p  className="c4t-red" role="alert">Tell us your first name</p>}
    
                <label className='full-width black-text'>
                Firstname
                <input className='form-control' type="text" placeholder='First Name' name="firstname" {...register("firstname", {required:true})} aria-invalid={errors.firstname ? "true" : "false"}   />
                </label> 
                {errors.lastname?.type === 'required' && <p  className="c4t-red" role="alert">Tell us your lastname</p>}
                <label className='full-width black-text'>
                Lastname
                <input className='form-control' type="text" placeholder='Last Name' name="lastname" {...register("lastname", {required:true})} aria-invalid={errors.lastname ? "true" : "false"}    />
                 </label> 
                 {errors.mobile?.type === 'required' && <p  className="c4t-red" role="alert">provide your mobile number</p>}
                <label className='full-width black-text'>
                Mobile No
                <input className='form-control' type="number" placeholder='Mobile no' name="mobile"   {...register("mobile", {required:true})} aria-invalid={errors.mobile ? "true" : "false"}    />
                 </label> 
                 {errors.email?.type === 'required' && <p  className="c4t-red" role="alert">provide your email address</p>}
                 <label className='full-width black-text'>
                Email
                <input className='form-control' type="email" placeholder='Email' name="email"  {...register("email", {required:true})} aria-invalid={errors.email ? "true" : "false"}    />
                 </label> 
                 {errors.designation?.type === 'required' && <p  className="c4t-red" role="alert">provide your designation</p>}
              <label className='full-width black-text'>
                Designation
                <select className='form-control variantType' name="designation" {...register("designation", {required:true})} aria-invalid={errors.designation ? "true" : "false"} >
                 <option value="">Select Designation </option>
                 <option value="Head of School"> Head of School </option>
                 <option value="Deputy Head of School"> Deputy Head of School </option>
                 <option value="Head of Curriculum"> Head of Curriculum  </option>
                 <option value="Executive Office"> Executive Director/CEO </option>
                </select>
                 </label>
             
             

        

                 <div classsName="col-12">
                     <h4 className='header-font head-text-mob'> Assessment Diet </h4>
                     <h6 className="body-font c4t-grey"> 3/5</h6>
                 </div>
                 <div className="row">
                    <div className="col-12"> 
                      What Coding Languages are you interested in testing on?
                    </div>
                    {errors.langSelect?.type === 'required' && <p  className="c4t-red" role="alert">Choose atleast One Computer Language</p>}

                    <div className="col-6 col-sm-2 mx-auto"> 
                    
                    <select className='form-control variantType' name="selectLang" {...register("selectLang", {required:true})} aria-invalid={errors.selectLang ? "true" : "false"} >
                 <option value="0">Select Language </option>
                 <option value="1"> HTML </option>
                 <option value="2"> HTML & CSS </option>
      
                </select>
                      </div>
                 </div>
              
           


                 <div classsName="col-12">
                     <h4 className='header-font head-text-mob'> Required Data </h4>
                     <h6 className="body-font c4t-grey"> 4/5</h6>
                 </div>
                 {errors.selectData?.type === 'required' && <p  className="c4t-red" role="alert">Pick a data point of interest</p>}
                 <div className="row">
                 <div className="col-12"> 
                      What Data are you most interested in receiving post assessment?
                    </div>
               <div className="col-6 col-sm-2 mx-auto"> 
                <select className='form-control variantType' name="selectData" {...register("selectData", {required:true})} aria-invalid={errors.selectData ? "true" : "false"} >
                 <option value="0">Select Data type </option>
                 <option value="1"> Summary Scores </option>
                 <option value="2"> Module Scores </option>
                 <option value="3"> Student entries </option>
                </select>
                 </div>
                 </div>
              
               

            
                 <div classsName="col-12">
                     <h4 className='header-font head-text-mob'> Candidate Details </h4>
                     <h6 className="body-font c4t-grey"> 5/5</h6>
                 </div> 
                 {errors.studentList?.type === 'required' && <p className="c4t-red" role="alert">Upload your candidate list</p>}
                <label className='full-width black-text' >
                 Upload your candidate List
                <input className='form-control' type="file" name="file" onChange={readUploadFile} />
                <input className='form-control' name="candidateJson" value={xcelRows} {...register("candidateJson", {required:false})} aria-invalid={errors.candidateJson ? "true" : "false"} />
                 </label> 
          
                   <button type="submit" id="registration" className="btn c4t-btn body-font thin-font standard-margin-top standard-margin-bottom disabled"> Complete Registration </button>
    </div>

     </form>

    </div>  
   </div>

  </Modal2>
  <Modal3 show={loadingModalIsOpen}>
   
   <div className='row'>
       <div className='col-12 text-center'>

       </div>  
       </div>
  
   </Modal3>

<Footer/>

 </div>
  
  )
  


}


export default Clim;