import React, { Component } from 'react';
import educator from '../images/educator.jpg';
import c4tlogo from '../images/c4t-logo.png';
import {Link} from 'react-router-dom';

const portalBg = {
    backgroundImage: `url(${educator})`,
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh"
}


const Portal = () => {
    return ( 
        <>
            <div className="portal" style={portalBg}>
            <div className="portal_form">
                <div className="row">
                    <div className="col-12 text-white">

                    <Link exact to="/">

                        <img src={c4tlogo} alt="c4tlogo"/><br/>
                    </Link>    
                        <small className="thin-font">Teachers achieve more </small><br/><small>with the same content </small><br/><small>when it's <span className="fat-font">connected.</span></small>
                    </div>
                    <div className="col-12">
                    <form className="mainForm">
                            <div class="mb-3 mt-5 text-center col">
                                <label class="body-font">Email Address</label>
                                <input type="text" class="form-control body-font" placeholder="Email Address" required/>
                            </div>
                            <div class="mb-3 text-center col">
                                <label >Password</label>
                                <input type="text" class="form-control body-font" placeholder="Password" required/>
                                <button class="btn c4t-btn body-font" type="submit">Login</button>
                            </div>
                                
                            <div class="form-group text-center">
                                    <label class="notice body-font">
                                        Don't have an Account. Apply for your school today
                                    </label> 
                                    <button class="btn c4t-btn body-font" type="submit">Apply</button> 
                            </div>
                        </form>
                        </div>
                        </div>
                </div>
        </div>
        </>
     );
}
 
export default Portal;