import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import landingBanner from '../images/home-banner.jpg';
import cslabs from '../images/CSLABS.jpg';
import cbooks from '../images/cbooks.png';
import mobilelab from '../images/mobile-lab.jpeg';
import Navigation from './navigation.js';
import mobBanner from '../images/product-banner-mobile.jpg';

import Footer from '../components/footer';


class products extends Component { 


  constructor(props){
       super(props)
       this.state={

       }

     }


componentDidMount(){ 
   window.scrollTo(0, 0)
}


render(){


return(

<div className=""> 
<Navigation />

  <div className="row container-fluid pOff mOff ">
  <div className="col-12 pOff top-banner top-banner-dsk products-banner"> 

  </div>

   <div className="col-12 pOff top-banner  top-banner-mob"> 
    <img src={mobBanner} class="img-fluid" />
  </div>

  <div className="col-12 c4t-blue-light-bg text-center pOff">
  <div className="col-12 col-md-8 mx-auto pOff standard-padding-rl-mobile"> 
      <h3 className="body-font white-text standard-margin-top"> Data-Led Product Cycle </h3> 

     <h5 className="body-font white-text  thin-font standard-margin-top standard-margin-bottom"> 
        Empirical Evidence from our research provides vital insights which allow us create both content and digital infrastructure to scale educations most critical components
        with a focus on Curriculum, Pedagogy and Collaboration. 
     </h5> 

     </div>
     </div>
  </div> 

  <div className="container standard-margin-top standard-margin-bottom">


   <div className="row text-center standard-margin-bottom">
   
     <div className="col-12 col-md-4 ">
        <div className="c4t-green-bg text-center"> 
             <h4 className="body-font fat-font white-text"> 
                Pedagogy
            </h4> 
            </div>
            <div className="col-12">

           <p className="thin-font body-font">
We are continously extending our technology infrastructure to provide pedagogical functions to
educators at scale. 


           </p>
           </div>
     </div>

      <div className="col-12 col-md-4 ">
         <div className="c4t-blue-bg text-center"> 
            <h4 className="body-font fat-font white-text"> 
                Curriculum
            </h4> 
           </div>

           <div className="col-12">

           <p className="thin-font body-font">
All content provided for technology education is developed from deep rooted research with 
trusted education partners and their Learners.


           </p>
           </div>
     </div>

       <div className="col-12 col-md-4 ">
         <div className="c4t-grey-bg text-center"> 
            <h4 className="body-font fat-font white-text"> 
                Collaboration
            </h4> 
           </div>
           <div className="col-12">

           <p className="thin-font body-font">
 By simulating the classroom content workflow, our infrastructure supports educator
 student collaboration at scale. 


           </p>
           </div>
     </div>

    </div> 


    <div className="row standard-margin-top standard-margin-bottom">

    <div className="col-12 col-md-6 text-center top-banner-dsk standard-margin-top"> 

       <img src={cslabs} className="img-fluid" />

     </div>

     <div className="col-12 col-md-6 standard-margin-top"> 

     <h2 className="body-font thin-font mobile-header-text">
        CS LABS 
       </h2>  

         
       <div className="c4t-blue-bg hor-bar-blue">

            
       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 

       <div className="col-12 col-md-6 text-center top-banner-mob standard-margin-top"> 

<img src={cslabs} className="img-fluid" />

</div>
 <p className="body-font thin-font"> 
 CS LABS is an online learning management system supporting the delivery of Code14 LABS Continuing professional development plan for ICT and language educators who are interested in 
 upskilling or pivoting into computer language education. <br/> Through CS LABS we deliver course curriculum, provide and accept assessment submissions and encourage community building within 
 the academic community centerd on Computer language education.
         </p>
      <a href="https://cs-labs.online" target="_blank" className="btn c4t-btn black-text body-font thin-font full-width"> Learn More </a>


      
      



     </div>



    </div> 

    <div className="row standard-margin-top standard-margin-bottom">



     <div className="col-12 col-md-6 standard-margin-top "> 

     <h2 className="body-font thin-font mobile-header-text">
         Andrew Teaches Coding
       </h2>  

         
       <div className="c4t-blue-bg hor-bar-blue">

            
       </div> 

       <div className="c4t-yellow-bg hor-bar-yellow">


       </div> 


    <div className="col-12 col-md-6 text-center top-banner-mob standard-margin-top"> 

<img src={cbooks} className="img-fluid"/>

</div>

      <p className="body-font thin-font"> 
      Andrew Teaches Coding contains linear curriculum content for teaching Grade 4, 5 and 6 computer language across HTML, CSS and JavaScript in a compounded format. 
   Each book has been developed in conjunction with trusted education partners and their students to ensure the content is both contextual and relateable to the respective classgroups.
  </p>
      <a  href="/andrewteachescoding/books" target="_blank" className="btn c4t-btn black-text body-font thin-font full-width"> Learn more </a>


      
      



     </div>

    <div className="col-12 col-md-6 text-center top-banner-dsk standard-margin-top"> 

       <img src={cbooks} className="img-fluid"/>

     </div>
    </div>


    <div className="row standard-margin-top standard-margin-bottom">

<div className="col-12 col-md-6 text-center top-banner-dsk standard-margin-top"> 

   <img src={mobilelab} className="img-fluid shadow standard-margin-bottom" />

 </div>

 <div className="col-12 col-md-6 standard-margin-top"> 

 <h2 className="body-font thin-font mobile-header-text">
     Code 4TEEN Lab 
   </h2>  

     
   <div className="c4t-blue-bg hor-bar-blue">

        
   </div> 

   <div className="c4t-yellow-bg hor-bar-yellow">


   </div> 


   <div className="col-12 col-md-6 text-center top-banner-mob standard-margin-top"> 

<img src={mobilelab} className="img-fluid shadow standard-margin-bottom" />

</div>

<p className="body-font thin-font"> 
   Install a 21st century Code 4TEEN lab at your school for as little as N5,000 per child for a flat rate of 30 students on a termly basis. Our lab comes with lifetime access to 
   code 4TEEN'S connected book infrastructure & Evidence-Led Curriculum for computer language as well as teacher mentorship and training to ensure a sustainable integration. 
</p>
  <Link To="#" className="btn c4t-btn black-text body-font thin-font full-width"> Learn More </Link>


  
  



 </div>



</div> 

    </div>
    <Footer/>
    </div>



	)



}

}

export default products;